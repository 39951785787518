import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import {API_URL, DOMAIN, EXTERNAL_API_URL, PROHIBITED_CHARACTERS_REGEX } from "@/const";
export default {
  data() {
    return {
      validate: {
        // 企業情報
        companyInfo: {
          legalPersonalityTypeId: { isValid: true, errMsg: "" },
          companyName: { isValid: true, errMsg: "" },
          zipcode: { isValid: true, errMsg: "" },
          prefecture: { isValid: true, errMsg: "" },
          address: { isValid: true, errMsg: "" },
          building: { isValid: true, errMsg: "" },
          tel: { isValid: true, errMsg: "" },
          fax: { isValid: true, errMsg: "" },
          staffName: { isValid: true, errMsg: "" },
          businessCode: { isValid: true, errMsg: "" },
          staffMail: { isValid: true, errMsg: "" },
        },
        collectLicence: [
          {
            licenceNo: { isValid: true, errMsg: "" },
            commonCityName: { isValid: true, errMsg: "" },
            licenceExpiredDate: { isValid: true, errMsg: "" },
            isExcellent: { isValid: true, errMsg: "" },
            targetJwnetItemList: { isValid: true, errMsg: "" },
            transshipment: [
              {
                name: { isValid: true, errMsg: "" },
                zipCode: { isValid: true, errMsg: "" },
                prefecture: { isValid: true, errMsg: "" },
                address: { isValid: true, errMsg: "" },
                building: { isValid: true, errMsg: "" },
                tel: { isValid: true, errMsg: "" },
                fax: { isValid: true, errMsg: "" },
                jwnetContractNo: { isValid: true, errMsg: "" },
                transshipmentStoreCode: { isValid: true, errMsg: "" },
                area: { isValid: true, errMsg: "" },
                storageUpperLimit: { isValid: true, errMsg: "" },
                height: { isValid: true, errMsg: "" },
                transshipmentJwnetItemList: { isValid: true, errMsg: "" },
              },
            ],
          },
        ],
      },

      dummyValidateEdit: {
        transshipment: {
          name: { isValid: true, errMsg: "" },
          zipCode: { isValid: true, errMsg: "" },
          prefecture: { isValid: true, errMsg: "" },
          address: { isValid: true, errMsg: "" },
          building: { isValid: true, errMsg: "" },
          tel: { isValid: true, errMsg: "" },
          fax: { isValid: true, errMsg: "" },
          jwnetContractNo: { isValid: true, errMsg: "" },
          transshipmentStoreCode: { isValid: true, errMsg: "" },
          area: { isValid: true, errMsg: "" },
          storageUpperLimit: { isValid: true, errMsg: "" },
          height: { isValid: true, errMsg: "" },
          transshipmentJwnetItemList: { isValid: true, errMsg: "" },
        },
        collectLicence: {
          licenceNo: { isValid: true, errMsg: "" },
          commonCityName: { isValid: true, errMsg: "" },
          licenceExpiredDate: { isValid: true, errMsg: "" },
          isExcellent: { isValid: true, errMsg: "" },
          targetJwnetItemList: { isValid: true, errMsg: "" },
          transshipment: [
            {
              name: { isValid: true, errMsg: "" },
              zipCode: { isValid: true, errMsg: "" },
              prefecture: { isValid: true, errMsg: "" },
              address: { isValid: true, errMsg: "" },
              building: { isValid: true, errMsg: "" },
              tel: { isValid: true, errMsg: "" },
              fax: { isValid: true, errMsg: "" },
              jwnetContractNo: { isValid: true, errMsg: "" },
              transshipmentStoreCode: { isValid: true, errMsg: "" },
              area: { isValid: true, errMsg: "" },
              storageUpperLimit: { isValid: true, errMsg: "" },
              height: { isValid: true, errMsg: "" },
              transshipmentJwnetItemList: { isValid: true, errMsg: "" },
            },
          ],
        },
      },

      isValidForm: {
        companyInfo: true,
        collectLicence: true,
      },
    };
  },
  methods: {
    // 会社名/営業所名 バリデートチェック
    async companyInfoValidateCheck() {
      const v = this.validate.companyInfo;
      let isValidForm = true;
      const f = this.formEdit;
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);

      if (!v.legalPersonalityTypeId.isValid) {
        v.legalPersonalityTypeId.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.companyName.isValid) {
        v.companyName.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.zipcode.isValid || !this.formEdit.addressInfo.zipCode) {
        v.zipcode.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      } else {
        const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.formEdit.addressInfo.zipCode}`;
          await axios
            .get(url, {adapter: axiosJsonpAdapter})
            .then((res) => {
              const results = res.data.results;
              if (results != null) {
                if (v.prefecture.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (this.isCompanyInfo) {
                      if (this.formEdit.addressInfo.selectedPrefectureTemp === address.address1) {
                        prefectureFlg = true;
                      }
                    }
                  });
                  if (!prefectureFlg) {
                    v.prefecture.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (v.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                    if (this.isCompanyInfo) {
                      if (this.formEdit.addressInfo.address === address.address2) {
                        addressFlg = true;
                      }
                    }
                  });
                  if (!addressFlg) {
                    v.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }
      if (!v.prefecture.isValid) {
        v.prefecture.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.address.isValid) {
        v.address.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (this.formEdit.addressInfo.buildingName && !v.building.isValid) {
        v.building.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.tel.isValid) {
        v.tel.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (this.formEdit.fax && !v.fax.isValid) {
        v.fax.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.staffName.isValid) {
        v.staffName.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.staffMail.isValid) {
        v.staffMail.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }

      if (f.businessCode) {
        if (f.businessCode.length != 6 || !halfAlphaRegexp.test(f.businessCode)) {
          v.businessCode.errMsg = "半角6文字で入力してください。";
          isValidForm = false;
        }
      }

      return isValidForm;
    },

    async collectLicenceValidateCheck() {
      let isValidForm = true;
      if (!this.validate.collectLicence) return;
      if (!this.formEdit.isLicence) return true; // 2021.09.15 許可証の任意対応
      for (let i = 0; i < this.validate.collectLicence.length; i++) {
        const v = this.validate.collectLicence[i];
        const t = this.formEdit.collectLicenceCreateList[i];

        if (!v.licenceNo.isValid) {
          v.licenceNo.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else if (t.wasteTypeId === 1) {
          // 産業廃棄物の許可証の場合
          await axios
          .get(API_URL.LICENCE.MUNICIPALITY + `?headNo=${t.licenceNo.substr(0, 3)}`)
          .catch((err) => {
            console.log(err);
            v.licenceNo.errMsg = "許可証番号が正しくありません。正しい許可証番号を入力してください。";
            isValidForm = false;
          });
        }
        if (!v.licenceExpiredDate.isValid) {
          v.licenceExpiredDate.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (t.wasteTypeId !== DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE && !v.commonCityName.isValid) {
          v.commonCityName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.isExcellent.isValid) {
          v.isExcellent.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (
          t.targetJwnetItemList == null ||
          t.targetJwnetItemList.length === 0
        ) {
          v.targetJwnetItemList.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        // 産業廃棄物の許可証の場合
        if (t.wasteTypeId === 1) {
          const isTransshipmentValidateCheck = await this.transshipmentValidateCheck(i);
          if (t.isTransshipment && !isTransshipmentValidateCheck) {
            isValidForm = false;
          }
        }
      }
      return isValidForm;
    },

    async transshipmentValidateCheck(i) {
      let isValidForm = true;
      if (!this.validate.collectLicence[i].transshipment) return;
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      for (let j = 0; j < this.validate.collectLicence[i].transshipment.length; j++) {
        const v = this.validate.collectLicence[i].transshipment[j];
        const t =
          this.formEdit.collectLicenceCreateList[i].transshipmentCreateList[j];
        // 積替保管場所名の禁則文字のチェック
        if (PROHIBITED_CHARACTERS_REGEX.test(t.name)) {
          v.name.errMsg = "登録できない文字が含まれています。";
          isValidForm = false;
        }
        if (!v.name.isValid) {
          v.name.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.zipCode.isValid) {
          v.zipCode.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else {
          const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${t.addressInfo.zipCode}`;
          await axios
            .get(url, {adapter: axiosJsonpAdapter})
            .then((res) => {
              const results = res.data.results;
              if (results != null) {
                if (v.prefecture.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (this.isCompanyInfo) {
                      if (t.addressInfo.selectedPrefectureTemp === address.address1) {
                        prefectureFlg = true;
                      }
                    }
                  });
                  if (!prefectureFlg) {
                    v.prefecture.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (v.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                    if (this.isCompanyInfo) {
                      if (t.addressInfo.address === address.address2) {
                        addressFlg = true;
                      }
                    }
                  });
                  if (!addressFlg) {
                    v.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (!v.prefecture.isValid) {
          v.prefecture.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.address.isValid) {
          v.address.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.tel.isValid) {
          v.tel.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (t.fax && !v.fax.isValid) {
          v.fax.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.jwnetContractNo.isValid) {
          v.jwnetContractNo.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!halfAlphaRegexp.test(t.jwnetContractNo)) {
          v.jwnetContractNo.errMsg = "JWNET加入者番号は半角英数字で入力してください。";
          isValidForm = false;
        }
        if (t.jwnetContractNo.length > 7) {
          v.jwnetContractNo.errMsg = "JWNET加入者番号は7桁で入力してください";
          isValidForm = false;
        }
        if (!v.transshipmentStoreCode.isValid) {
          v.transshipmentStoreCode.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (t.area && !v.area.isValid) {
          v.area.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (t.storageUpperLimit && !v.storageUpperLimit.isValid) {
          v.storageUpperLimit.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (t.height && !v.height.isValid) {
          v.height.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!t.transshipmentJwnetItemList.length) {
          v.transshipmentJwnetItemList.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      }
      return isValidForm;
    },

    async runValidate() {
      let isValidForm = false;
      this.isValidForm.companyInfo = await this.companyInfoValidateCheck();
      this.isValidForm.collectLicence = await this.collectLicenceValidateCheck();
      for (let k in this.isValidForm) {
        if (this.isValidForm[k]) {
          isValidForm = true;
        } else {
          isValidForm = false;
          break;
        }
      }

      return isValidForm;
    },
  },
};
