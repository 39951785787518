import axios from "axios";
import { EXTERNAL_API_URL } from "@/const";
import axiosJsonpAdapter from "axios-jsonp";

export default {
  data() {
    return {
      isAddressSelectModalShow: false,
      addressResults: [], // 住所検索モーダル内で使用
      selectedAddress: {}, // 【企業情報】住所検索結果（モーダル展開時はモーダル内選択結果）
      selectedInvoiceAddress: {}, // 【請求先】住所検索結果（モーダル展開時はモーダル内選択結果）
      isCompanyInfo: true, // も＾ダル展開時に企業情報か請求先かを判定
      addressInfo: {},
    };
  },

  methods: {
    /**
     * 住所検索押下
     * isCompanyInfo ? 企業情報の郵便番号 : 請求先の郵便番号
     */
    searchAddressHandler(addressInfo) {
      const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${addressInfo.zipCode}`;
      axios
        .get(url,{ adapter: axiosJsonpAdapter })
        .then((res) => {
          const results = res.data.results;

          if (results === null) {
            alert("該当住所が見つかりません、郵便番号をご確認ください。");
          } else if (results.length === 1) {
            // 単一住所のみヒット
            addressInfo.selectedPrefectureTemp = results[0].address1;
            addressInfo.address = results[0].address2;
            addressInfo.buildingName = results[0].address3;
          } else {
            // 複数住所のヒット
            this.addressInfo = addressInfo;
            this.addressResults = results;
            this.isAddressSelectModalShow = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    selectAddressHandler(address) {
      this.addressInfo.selectedPrefectureTemp = address.address1;
      this.addressInfo.address = address.address2;
      this.addressInfo.buildingName = address.address3;
      this.addressResults = [];
    },
  },
};
