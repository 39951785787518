import axios from "axios";
import { API_URL } from "@/const";
import formValidationEdit from "../mixins/form/formValidationEdit";

export default {
  mixins: [formValidationEdit],

  data() {
    return {
      prefectures: [],
      legalPersonalityTypes: [],
      jwnetItemLicences: [],
      duplicatedNameInfoList: [], //重複する名称情報
    };
  },

  methods: {
    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      return axios
        .get(API_URL.DOMAIN.PREFECTURES)
        .then((res) => {
          this.prefectures = res.data.prefectures;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 法人格選択肢取得API   DOMAIN.LEGAL_PERSONALITY_TYPE
    getLegalPersonalityTypesApi() {
      return axios
        .get(API_URL.DOMAIN.LEGAL_PERSONALITY_TYPE)
        .then((res) => {
          this.legalPersonalityTypes = res.data.legalPersonalityTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 許可書用、廃棄物種類選択肢取得API   DOMAIN.JWNET_ITEM_TYPE_LICENCE
    getJwnetItemLicencesApi() {
      return axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE_LICENCE)
        .then((res) => {
          this.jwnetItemLicences = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //ファイルアップロードAPI
    uploadApi(file) {
      const params = new FormData();
      params.append("file", file);
      params.append("fileName", file.name);
      return axios
        .post(API_URL.COMMON.FILE_UPLOAD, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return res.data.filePath;
        });
    },

    // 運搬事業者情報取得API
    getCollectorApi(collectorId) {
      return axios
        .get(API_URL.COLLECTOR.DETAIL + collectorId)
        .then((res) => {
          const data = res.data;
          // 企業情報
          this.formEdit = {
            businessCode: data.collectorBaseInfo.businessCode,
            legalPersonalityTypeId:
              data.collectorBaseInfo.legalPersonalityType.id,
            companyName: data.collectorBaseInfo.name,
            tel: data.collectorBaseInfo.tel,
            fax: data.collectorBaseInfo.fax,
            addressInfo: {
              zipCode: data.collectorBaseInfo.zipCode,
              selectedPrefectureTemp: data.collectorBaseInfo.prefectures.name,
              prefecturesId: data.collectorBaseInfo.prefectures.id,
              address: data.collectorBaseInfo.address,
              buildingName: data.collectorBaseInfo.buildingName,
            },
            staffName: data.staffName,
            staffMail: data.staffMail,
            isLicence: !!data.licenceMunicipalityList.length || !!data.licenceCityList.length,
            collectLicenceCreateList: [],
            commonWasteCollectLicenceCreateList:[],
          };
          this.validate.collectLicence = [];
          // 許可証情報
          if (
            data.licenceMunicipalityList.flatMap((m) => m.collectLicenceList)
              .length
          )
            this.validate.collectLicence = [];
          // 運搬許可証登録リスト
          data.licenceMunicipalityList.forEach((m) => {
            m.collectLicenceList.forEach((licence) => {
              const newLicence = {
                wasteTypeId: 1,
                collectLicenceId: licence.collectLicenceId,
                licenceNo: licence.licenceNo,
                licenceExpiredDate: licence.licenceExpiredDate,
                isExcellent: licence.isExcellent,
                licenceFileName: licence.licenceFileName,
                isActive: licence.isActive,
                isUpdate: licence.isUpdate,
                targetJwnetItemList: licence.targetJwnetItemList,
                isTransshipment: !!licence.transshipmentList.length,
                transshipmentCreateList: [],
              };
              this.municipalityList.push({
                licenceNo: newLicence.licenceNo,
                municipality: m.municipalityName,
              });
              const newLicenceValidation = JSON.parse(
                JSON.stringify(this.dummyValidateEdit.collectLicence)
              );

              // 積替保管場所情報
              newLicenceValidation.transshipment = [];
              licence.transshipmentList.forEach((transshipment) => {
                const newTransshipment = {
                  transshipmentStorageId: transshipment.id,
                  name: transshipment.name,
                  addressInfo: {
                    zipCode: transshipment.addressInfo.zipCode,
                    selectedPrefectureTemp:
                      transshipment.addressInfo.prefecturesName,
                    prefecturesId: transshipment.addressInfo.prefecturesId,
                    address: transshipment.addressInfo.address,
                    buildingName: transshipment.addressInfo.buildingName,
                  },
                  tel: transshipment.tel,
                  fax: transshipment.fax,
                  jwnetContractNo: transshipment.jwnetContractNo,
                  transshipmentStoreCode: transshipment.transshipmentStoreCode,
                  area: transshipment.area,
                  storageUpperLimit: transshipment.storageUpperLimit,
                  height: transshipment.height,
                  transshipmentJwnetItemList:
                    transshipment.transshipmentJwnetItemList,
                };

                newLicence.transshipmentCreateList.push(newTransshipment);
                newLicenceValidation.transshipment.push(
                  JSON.parse(
                    JSON.stringify(this.dummyValidateEdit.transshipment)
                  )
                );
              });

              this.formEdit.collectLicenceCreateList.push(newLicence);
              this.validate.collectLicence.push(newLicenceValidation);
            });
          });

          // 一般廃棄物運搬許可証登録リスト
          data.licenceCityList.forEach((lc) => {
            lc.collectLicenceList.forEach((licence) => {
              const newLicence = {
                wasteTypeId: 2,
                collectLicenceId: licence.collectLicenceId,
                licenceNo: licence.licenceNo,
                licenceExpiredDate: licence.licenceExpiredDate,
                isExcellent: licence.isExcellent,
                licenceFileName: licence.licenceFileName,
                commonCityName: lc.cityName,
                isActive: licence.isActive,
                isUpdate: licence.isUpdate,
                targetJwnetItemList: licence.targetJwnetItemList,
              };

              const newLicenceValidation = JSON.parse(
                JSON.stringify(this.dummyValidateEdit.collectLicence)
              );
              this.formEdit.collectLicenceCreateList.push(newLicence);
              this.validate.collectLicence.push(newLicenceValidation);
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 所属による名称が重複チェックAPI
    checkDuplicationName(duplicationNameCheckInfoList) {
      return axios
          .post(API_URL.DUPLICATION.NAME, {duplicationNameCheckInfoList : duplicationNameCheckInfoList})
          .then((res) => {
            this.duplicatedNameInfoList = res.data.duplicatedNameInfoList;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
